import React from "react";
import styles from './Middle.module.css';
import logo1 from '../../../assets/aboutUs/middle1.png';
import logo2 from '../../../assets/aboutUs/middle2.png';
import logo3 from '../../../assets/aboutUs/middle3.png';

const Middle = () =>{
    return(
        <div style={{backgroundColor: '#f8f8f8'}} className='overflow-hidden'>
            <div className={`container ${styles.contain}`}>
                <p data-aos='fade-down-right' data-aos-offset="170" className={styles.mainHead}>Our Expertise</p>
                <p data-aos='fade-up-left' data-aos-offset="170" className={styles.mainContent}>
                    We specialize in a wide range of web development services designed to help your business succeed. Our team is committed to delivering innovative solutions that meet your unique needs and exceed your expectations.
                </p>

                <div className="row">
                    <div data-aos='fade-right' data-aos-offset="170" className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo1} alt="Web Design"/>
                            <p className={styles.head}>Web Design</p>
                            <p className={styles.content}>
                                Creating visually stunning and user-friendly websites tailored to your business needs.
                            </p>
                        </div>
                    </div>

                    <div data-aos='fade-up' className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo2} alt="E-commerce"/>
                            <p className={styles.head}>E-commerce</p>
                            <p className={styles.content}>
                                Developing robust e-commerce platforms that drive sales and enhance customer experience.
                            </p>
                        </div>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <div className={styles.box}>
                            <img src={logo3} alt="SEO Optimization"/>
                            <p className={styles.head}>SEO Optimization</p>
                            <p className={styles.content}>
                                Implementing effective SEO strategies to increase your online visibility and attract more traffic.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Middle;