import seo1 from '../../assets/individualService/Image.png';
import seo2 from '../../assets/individualService/Image_2.png';

const data = [
    {
        id: 1,
        heading: 'SEO Service',
        content: 'Boost your online presence and attract more visitors with our comprehensive SEO services. We implement proven strategies to improve your search engine rankings and drive organic traffic to your website.',
        contents: [
            {
                heading: 'Get The Maximum Benefits',
                img: seo1,
                content: 'Maximize your website’s potential with our expert SEO strategies. We focus on optimizing your site for search engines, improving your ranking, and increasing organic traffic. Our techniques are designed to deliver long-term results and help your business grow. With our services, you can expect enhanced visibility, more leads, and better conversion rates.'
            },
            {
                heading: 'Stay Ahead of the Competition',
                img: seo2,
                content: 'Stay ahead of your competitors with our advanced SEO solutions. We keep up with the latest search engine algorithms to ensure your website stays relevant and maintains its top position. Our continuous monitoring and adjustments keep you at the forefront of search results, ensuring consistent performance and growth. Partner with us to experience the benefits of a robust SEO strategy.'
            },
        ]
    }
    ,
    {
        id: 2,
        heading: 'Web Migration Services',
        content: 'Seamlessly transition your website to a new platform with minimal downtime and data loss. Our expert team ensures a smooth migration process, preserving your site’s integrity and functionality.',
        contents: [
            {
                heading: 'Effortless Migration',
                img: seo1,
                content: 'Our web migration services ensure a smooth and hassle-free transition of your website to a new platform. We handle all aspects of the migration process, including data transfer, domain management, and SEO preservation. With our comprehensive approach, you can focus on your business while we take care of the technical details, ensuring your website remains fully operational and efficient.'
            },
            {
                heading: 'Secure and Reliable',
                img: seo2,
                content: 'We prioritize the security and integrity of your data during the migration process. Our team uses reliable methods to safeguard your information, ensuring that your website remains secure and operational throughout the transition. We also provide post-migration support to address any issues promptly and ensure everything runs smoothly on the new platform.'
            },
        ]
    },
    {
        id: 3,
        heading: 'Corporate Web Design',
        content: 'Create a strong online presence with a professional and visually appealing corporate website. Our designs are tailored to reflect your brand identity and engage your target audience.',
        contents: [
            {
                heading: 'Tailored Design Solutions',
                img: seo1,
                content: 'Our corporate web design services are tailored to meet the specific needs of your business. We create custom designs that reflect your brand identity and engage your target audience, helping you make a lasting impression online. Our team collaborates closely with you to understand your vision and translate it into a visually stunning and functional website.'
            },
            {
                heading: 'Responsive and User-Friendly',
                img: seo2,
                content: 'We design websites that are not only visually stunning but also responsive and user-friendly. Our designs ensure that your site provides an excellent user experience across all devices, from desktops to mobile phones. With intuitive navigation and clear calls-to-action, we help you convert visitors into loyal customers and achieve your business goals.'
            },
        ]
    },
    {
        id: 4,
        heading: 'E-Commerce System',
        content: 'Build a robust and scalable e-commerce platform to enhance your online sales. Our solutions are designed to provide a seamless shopping experience for your customers.',
        contents: [
            {
                heading: 'Custom E-Commerce Solutions',
                img: seo1,
                content: 'Our e-commerce system services provide you with custom solutions to fit your specific business needs. From product listings to payment processing, we cover all aspects to ensure your online store operates smoothly and efficiently. Our team works closely with you to develop features that enhance user experience and drive sales growth.'
            },
            {
                heading: 'Boost Your Sales',
                img: seo2,
                content: 'We implement advanced e-commerce strategies to boost your sales and enhance customer satisfaction. Our solutions are designed to provide a seamless shopping experience, encouraging repeat business and customer loyalty. With features like personalized recommendations, secure checkout, and mobile optimization, we help you maximize your online revenue.'
            },
        ]
    },
    {
        id: 5,
        heading: 'Web Maintenance Service',
        content: 'Keep your website running smoothly and securely with our web maintenance services. We handle regular updates, security checks, and performance optimizations to ensure your site remains in top condition.',
        contents: [
            {
                heading: 'Regular Updates',
                img: seo1,
                content: 'Our web maintenance services include regular updates to ensure your website stays current and functional. We handle everything from software updates to content management, keeping your site in top condition. With our proactive approach, you can avoid potential issues and ensure your website continues to perform optimally.'
            },
            {
                heading: 'Enhanced Security',
                img: seo2,
                content: 'Protect your website from security threats with our comprehensive maintenance services. We implement security measures to prevent breaches and ensure your data remains safe and secure. Our team continuously monitors your site for vulnerabilities, providing quick responses to any potential threats and keeping your business protected.'
            },
        ]
    },
    {
        id: 6,
        heading: 'UI/UX Design',
        content: 'Create engaging and intuitive user interfaces with our expert UI/UX design services. We focus on delivering an exceptional user experience that enhances customer satisfaction and retention.',
        contents: [
            {
                heading: 'User-Centered Design',
                img: seo1,
                content: 'Our UI/UX design services focus on creating user-centered designs that enhance usability and engagement. We conduct thorough user research to understand your audience and design interfaces that meet their needs and expectations. By prioritizing user experience, we help you create products that are both functional and enjoyable to use.'
            },
            {
                heading: 'Interactive and Engaging',
                img: seo2,
                content: 'We design interactive and engaging user interfaces that provide a seamless experience. Our designs aim to improve user interaction and satisfaction, making your digital products more effective and enjoyable to use. With intuitive navigation, clear layouts, and visually appealing elements, we help you achieve a competitive edge in the market.'
            },
        ]
    },
    {
        id: 7,
        heading: 'Social Media Marketing',
        content: 'Boost your online presence and engage with your audience through our comprehensive social media marketing services.',
        contents: [
            {
                heading: 'Effective Campaigns',
                img: seo1,
                content: 'Our social media marketing services help you create effective campaigns that resonate with your target audience. We develop strategies that increase brand awareness and drive engagement across various platforms.'
            },
            {
                heading: 'Content Creation',
                img: seo2,
                content: 'We provide high-quality content creation services to ensure your social media profiles stand out. Our team produces engaging posts, graphics, and videos that capture attention and promote interaction with your brand.'
            },
        ]
    },
    {
        id: 8,
        heading: 'Quality Assurance',
        content: 'Ensure the highest quality for your software and applications with our comprehensive quality assurance services.',
        contents: [
            {
                heading: 'Thorough Testing',
                img: seo1,
                content: 'Our quality assurance services include thorough testing to identify and fix bugs, ensuring your software runs smoothly. We use a variety of testing methods to cover all aspects of functionality and performance.'
            },
            {
                heading: 'Continuous Improvement',
                img: seo2,
                content: 'We focus on continuous improvement to maintain the highest quality standards. Our team regularly reviews and updates testing procedures to keep up with industry best practices and ensure your applications remain reliable.'
            },
        ]
    },
    {
        id: 9,
        heading: 'Cloud and IoT Based Solutions',
        content: 'Leverage the power of cloud computing and IoT to enhance your business operations with our innovative solutions.',
        contents: [
            {
                heading: 'Scalable Cloud Solutions',
                img: seo1,
                content: 'Our cloud solutions provide scalable and flexible resources to meet your business needs. We help you migrate to the cloud, manage your infrastructure, and optimize performance for maximum efficiency.'
            },
            {
                heading: 'IoT Integration',
                img: seo2,
                content: 'Integrate IoT devices seamlessly into your operations with our expert services. We offer end-to-end solutions, from device connectivity to data analytics, enabling you to harness the full potential of IoT technology.'
            },
        ]
    },
    {
        id: 10,
        heading: 'IT Consultancy',
        content: 'Transform your business with strategic IT consultancy services that align technology with your business goals.',
        contents: [
            {
                heading: 'Strategic Planning',
                img: seo1,
                content: 'Our IT consultancy services include strategic planning to help you make informed technology decisions. We assess your current IT infrastructure and provide recommendations to improve efficiency and achieve your objectives.'
            },
            {
                heading: 'Expert Guidance',
                img: seo2,
                content: 'Benefit from expert guidance on the latest technology trends and best practices. Our consultants work closely with you to implement solutions that drive growth and innovation, ensuring your business stays ahead of the competition.'
            },
        ]
    },
    {
        id: 11,
        heading: 'AI Solutions',
        content: 'Unlock the potential of artificial intelligence to drive innovation and efficiency in your business operations.',
        contents: [
            {
                heading: 'AI Strategy Development',
                img: seo1,
                content: 'We help you develop a robust AI strategy that aligns with your business goals. Our services include identifying opportunities for AI integration and creating a roadmap for implementation, ensuring a smooth transition to AI-powered operations.'
            },
            {
                heading: 'Custom AI Solutions',
                img: seo2,
                content: 'Our team designs and develops custom AI solutions tailored to your specific needs. From machine learning models to natural language processing, we provide advanced AI technologies that enhance decision-making and automate processes.'
            },
        ]
    },
]

export default data;