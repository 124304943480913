import image1 from '../../assets/home/testimonial1.png';
import image2 from '../../assets/home/testimonial2.png';
import image3 from '../../assets/home/testimonial2.png';

export const fakeData = [
    {
        id: 1,
        img: image1,
        name: 'Howard Arlene',
        profession: 'Finance Manager',
        message: "Your company is truly upstanding and is behind its product 100%. It's the perfect solution for our business. It has really helped our business."
    },
    {
        id: 2,
        img: image2,
        name: 'Miles Esther',
        profession: 'Public Relations Specialist',
        message: "You won't regret it. I was amazed at the quality of it. I am really satisfied with my it."
    },
    {
        id: 3,
        img: image3,
        name: 'Pena Norma',
        profession: 'Public Relations Specialist',
        message: "It fits our needs perfectly. Man, this thing is getting better and better as I learn more about it. You've saved our business!"
    },
    {
        id: 4,
        img: image1,
        name: 'Howard Arlene',
        profession: 'Finance Manager',
        message: "Your company is truly upstanding and is behind its product 100%. It's the perfect solution for our business. It has really helped our business."
    },
    {
        id: 5,
        img: image2,
        name: 'Miles Esther',
        profession: 'Public Relations Specialist',
        message: "You won't regret it. I was amazed at the quality of it. I am really satisfied with my it."
    },
    {
        id: 6,
        img: image3,
        name: 'Pena Norma',
        profession: 'Public Relations Specialist',
        message: "It fits our needs perfectly. Man, this thing is getting better and better as I learn more about it. You've saved our business!"
    },
]