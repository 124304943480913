import img1 from '../../../assets/aboutUs/bottom1.png';
import img2 from '../../../assets/aboutUs/bottom2.png'

const fakeData = [
    {
        id: 1,
        heading: 'Innovative Solutions',
        img: img1,
        content: 'Our team excels in creating innovative solutions that cater to the unique needs of our clients. We blend creativity with technology to deliver exceptional results that drive success. Each project is approached with a fresh perspective, ensuring originality and effectiveness. Our commitment to excellence guarantees that we not only meet but exceed your expectations.'
    },
    {
        id: 2,
        heading: 'Creative Excellence',
        img: img2,
        content: 'We pride ourselves on our ability to deliver creative excellence in every project we undertake. Our expertise spans various domains, enabling us to craft custom solutions that are both visually appealing and highly functional. By understanding your vision and goals, we transform ideas into reality, providing you with a competitive edge in the digital landscape.'
    },
]

export default fakeData;