import seo from '../../assets/home/seo.png';
import web from '../../assets/home/web.png';
import cwd from '../../assets/home/cwd.png';
import eco from '../../assets/home/e-co.png';
import wms from '../../assets/home/wms.png';
import uxui from '../../assets/home/ux-ui.png';

const data = [
    {
        id: 1,
        heading: 'SEO Service',
        content: 'Enhance your online visibility and attract more traffic with our expert SEO services. We employ the latest strategies and techniques to improve your search engine rankings and drive organic growth.',
        to: '/',
        icon: seo,
    },
    {
        id: 2,
        heading: 'Web Migration Services',
        content: 'Seamlessly transition your website to a new platform with our comprehensive web migration services. We ensure minimal downtime and data integrity throughout the process.',
        to: '/',
        icon: web,
    },
    {
        id: 3,
        heading: 'Corporate Web Design',
        content: 'Elevate your corporate presence with a professional and aesthetically pleasing website. Our design services focus on creating a strong brand identity and an exceptional user experience.',
        to: '/',
        icon: cwd,
    },
    {
        id: 4,
        heading: 'E-Commerce System',
        content: 'Build a robust and scalable e-commerce platform with our end-to-end solutions. We provide everything you need to manage your online store, from design to payment integration.',
        to: '/',
        icon: eco,
    },
    {
        id: 5,
        heading: 'Web Maintenance Service',
        content: 'Ensure your website remains up-to-date and secure with our reliable web maintenance services. We handle regular updates, security checks, and performance optimizations.',
        to: '/',
        icon: wms,
    },
    {
        id: 6,
        heading: 'UI/UX Design',
        content: 'Create an intuitive and engaging user interface with our expert UI/UX design services. We focus on delivering an exceptional user experience that enhances customer satisfaction and retention.',
        to: '/',
        icon: uxui,
    },
    {
        id: 7,
        heading: 'Social Media Marketing',
        content: 'Boost your online presence and engage with your audience through our comprehensive social media marketing services.',
        to: '/',
        icon: uxui,
    },
    {
        id: 8,
        heading: 'Quality Assurance',
        content: 'Ensure the highest quality for your software and applications with our comprehensive quality assurance services.',
        to: '/',
        icon: uxui,
    },
    {
        id: 9,
        heading: 'Cloud and IoT Based Solutions',
        content: 'Leverage the power of cloud computing and IoT to enhance your business operations with our innovative solutions.',
        to: '/',
        icon: uxui,
    },
    {
        id: 10,
        heading: 'IT Consultancy',
        content: 'Transform your business with strategic IT consultancy services that align technology with your business goals.',
        to: '/',
        icon: uxui,
    },
    {
        id: 11,
        heading: 'AI Solutions',
        content: 'Unlock the potential of artificial intelligence to drive innovation and efficiency in your business operations.',
        to: '/',
        icon: uxui,
    },
]


export default data;