import React from "react";
import styles from './ChoosingUs.module.css';
import experience from '../../assets/home/experience.png';
import communication from '../../assets/home/communication.png';
import comfort from '../../assets/home/comfort.png';

const ChoosingUs = () =>{
    return(
        <div style={{backgroundColor: '#fff1e5'}}>
            <div className="container overflow-hidden">
                <p data-aos='slide-right' data-aos-offset="220" className={styles.heading}>Top three the reason why you should choosing us.</p>
                <p data-aos='slide-left' className={styles.sub_text}>In our pursuit of excellence, such instances are encountered.</p>
                <div className={`row ${styles.sub}`}>
                    <div data-aos='fade-right' className="col-md-4">
                        <img src={experience} alt="experience"/>
                        <p className={styles.logo_head}>Experience</p>
                        <p className={styles.logo_text}>We understand that user satisfaction is crucial to the success of any software application. That's why our team of designers and developers works tirelessly to create intuitive interfaces and seamless workflows.</p>
                    </div>

                    <div data-aos='fade' className="col-md-4">
                        <img src={communication} alt="experience"/>
                        <p className={styles.logo_head}>Easy Communication</p>
                        <p className={styles.logo_text}>Communication is key to any successful partnership. Our team is always available to listen to your feedback, answer your questions, and provide regular updates on the progress of your project.</p>
                    </div>

                    <div data-aos='fade-left' className="col-md-4">
                        <img src={comfort} alt="experience"/>
                        <p className={styles.logo_head}>Comfortable</p>
                        <p className={styles.logo_text}>We believe that collaboration should be comfortable and stress-free. That's why we go above and beyond to create a supportive and inclusive environment for our clients.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChoosingUs;